export function formatCurrency(value) {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    return formatter.format(value);
}

export function getDomain() {
    return window.location.origin.startsWith("http://localhost:8080")
        ? "https://localhost:44307"
        : window.location.origin;
}

export function groupBy(list, key) {
    return list.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

export function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export function pluralize(resource, count) {
    if (count == 1) {
        return resource;
    }

    return resource + "s";
}

export function sum(list) {
    if (!list.length) return 0;

    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    return list.reduce(reducer);
}

export function formatPhoneNumber(phoneNumber) {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return ["(", match[1], ") ", match[2], "-", match[3]].join("");
    }
    return null;
}

export function toTitleCase(value) {
    return value.replace(/\b./g, (txt) => txt.charAt(0).toUpperCase());
}

export function except(source, ...keys) {
    keys = new Set(keys);

    return Object.keys(source).reduce((extracted, key) => {
        if (!keys.has(key)) {
            extracted[key] = source[key];
        }

        return extracted;
    }, {});
}

export function toISOLocal(d) {
    var z = (n) => ("0" + n).slice(-2);
    var zz = (n) => ("00" + n).slice(-3);
    var off = d.getTimezoneOffset();
    var sign = off < 0 ? "+" : "-";
    off = Math.abs(off);

    return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        sign +
        z((off / 60) | 0) +
        ":" +
        z(off % 60)
    );
}

export default {
    getDomain,
    formatCurrency,
    groupBy,
    onlyUnique,
    pluralize,
    sum,
    formatPhoneNumber,
    toTitleCase,
    except,
    toISOLocal,
};
