<template>
    <v-container fluid grid-list-xl class="mt-8 mb-8">
        <v-layout wrap>
            <v-layout class="pt-3" justify-center v-if="!status">
                <v-card
                    class="pa-5 mx-6 yk-card"
                    style="width: 700px;"
                    v-bind:class="{ 'full-width': isMobile() }"
                >
                    <v-layout
                        row
                        align-start
                        justify-start
                        class="mb-3 mt-2"
                        v-bind:style="getWidthStyle()"
                    >
                        <v-layout
                            column
                            class="ml-4 mt-1"
                            style="
                                line-height: 24px;
                                font-size: 14px;
                                font-weight: 500;
                                letter-spacing: 0.010em;"
                        >
                            <div>Name:</div>
                            <div>Payment Type:</div>
                            <div>Card Number:</div>
                            <div>Payment Date:</div>
                        </v-layout>
                        <v-layout
                            class="mt-1"
                            column
                            style="line-height: 24px; font-size: 14px;
                letter-spacing: 0.010em;"
                        >
                            <div>{{ payment.billingName }}</div>
                            <div>Credit Card</div>
                            <div>
                                **** **** ****
                                {{ payment.x_Card_Num.substring(12) }}
                            </div>
                            <div>{{ formatDate(payment.paymentDate) }}</div>
                        </v-layout>
                    </v-layout>
                    <div v-if="payments && payments.length">
                        <div class="subtitle-1 ml-2 mt-4 mb-1">Payment</div>
                        <v-data-table
                            disable-sort
                            :mobile-breakpoint="NaN"
                            :headers="headers"
                            :items="payments"
                            class="elevation-1"
                            hide-default-footer
                        ></v-data-table>
                    </div>
                    <div v-if="schedules">
                        <div class="subtitle-1 ml-2 mt-4 mb-1">Schedules</div>
                        <v-data-table
                            :mobile-breakpoint="NaN"
                            :headers="scheduleHeaders"
                            :items="schedules"
                            class="elevation-1"
                            hide-default-footer
                        ></v-data-table>
                    </div>

                    <v-card-text>
                        <p>
                            I authorize
                            <strong>CAMP YESHIVA KTANA</strong> to charge the
                            credit card indicated in this web form according to
                            the terms outlined above. This payment authorization
                            is for the goods/services described above, for the
                            amount indicated above only, and is valid for one
                            time use only or according to the payment plan
                            described above. I certify that I am an authorized
                            user of this credit card and that I will not dispute
                            the payment with my credit card company; so long as
                            the transaction corresponds to the terms indicated
                            in this web form.
                        </p>
                    </v-card-text>
                    <!-- <v-layout row class="mt-4 mb-2 mr-2">
                        <v-spacer></v-spacer>
                        <vue-recaptcha
                            :loadRecaptchaScript="true"
                            sitekey="6Lcq2uEfAAAAAB6lNki5U_s4mwoHf-s4Vs4jYzmx"
                            @verify="onCaptchaVerified"
                        ></vue-recaptcha>
                    </v-layout> -->
                    <!-- <div
                        class="my-3 mr-2"
                        style="color: red; text-align: right"
                        v-if="recaptchaError"
                    >
                        {{ recaptchaError }}
                    </div> -->
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#4289b0" outlined @click="goToDashboard"
                            >Back</v-btn
                        >
                        <v-btn
                            :disabled="processing"
                            class="button"
                            @click="processPayment"
                            >I Agree, Submit</v-btn
                        >
                    </v-card-actions>
                    <v-progress-linear
                        class="mx-2 mt-2"
                        v-if="processing"
                        indeterminate
                        color="#4289b0"
                    ></v-progress-linear>
                </v-card>
            </v-layout>
            <v-card
                v-else-if="status == 'E'"
                class="pa-3 mx-3"
                style="width: 100%;"
            >
                <v-layout justify-center>
                    <v-icon
                        style="height: 100px;"
                        size="100"
                        class="mt-2"
                        color="rgb(243, 81, 35)"
                        >mdi-alert-circle</v-icon
                    >
                </v-layout>
                <v-layout justify-center>
                    <v-card-title class="font-weight-medium title"
                        >An Error Occurred</v-card-title
                    >
                </v-layout>
                <v-layout justify-center>
                    <v-card-text
                        class="pa-0 text-center body-1 red--text"
                        v-if="
                            error &&
                                error.message &&
                                error.message.includes('Card is not authorized')
                        "
                        >{{ error.message }}</v-card-text
                    >
                </v-layout>
                <v-layout justify-center>
                    <v-card-actions class="pb-3">
                        <v-btn color="#4289b0" outlined @click="goBack"
                            >try again</v-btn
                        >
                    </v-card-actions>
                </v-layout>
            </v-card>
            <v-card
                v-else-if="status == 'D'"
                class="pa-3 mx-3"
                style="width: 100%;"
            >
                <v-layout justify-center>
                    <v-icon
                        style="height: 100px;"
                        size="100"
                        class="mt-2"
                        color="rgb(243, 81, 35)"
                        >mdi-alert</v-icon
                    >
                </v-layout>
                <v-layout justify-center>
                    <v-card-title class="font-weight-medium title"
                        >Payment Declined</v-card-title
                    >
                </v-layout>
                <v-layout justify-center>
                    <v-card-text class="body-1 text-center"
                        >We’re sorry but your credit card payment was declined.
                        Please use an alternative credit card and try submitting
                        again. If you experience further problems, please call
                        us at 718-963-9260 Ext. 43 to complete your transaction
                        over the phone.</v-card-text
                    >
                </v-layout>
                <v-layout justify-center>
                    <v-card-actions class="pb-3">
                        <v-btn color="#4289b0" outlined @click="goBack"
                            >try again</v-btn
                        >
                    </v-card-actions>
                </v-layout>
            </v-card>
            <v-card
                v-else-if="status == 'A'"
                class="pa-5 mx-3 yk-card"
                style="width: 100%;"
            >
                <div v-if="payments && payments.length">
                    <v-layout justify-center>
                        <v-icon
                            style="height: 100px;"
                            size="100"
                            color="#A5DC86"
                            class="mt-2"
                            >mdi-check-circle-outline</v-icon
                        >
                    </v-layout>
                    <v-layout justify-center>
                        <v-card-title class="font-weight-medium title"
                            >Payment Approved</v-card-title
                        >
                    </v-layout>
                    <v-layout justify-center>
                        <v-card-text class="body-1 text-center pt-1"
                            >Thank you for your payment</v-card-text
                        >
                    </v-layout>
                </div>
                <div v-if="schedules">
                    <v-divider
                        class="mt-3"
                        v-if="payment && payments.length"
                    ></v-divider>
                    <v-layout justify-center>
                        <v-icon
                            style="height: 100px;"
                            size="100"
                            color="#A5DC86"
                            class="mt-2"
                            >mdi-check-circle-outline</v-icon
                        >
                    </v-layout>
                    <v-layout justify-center>
                        <v-card-title class="font-weight-medium title"
                            >Payments Scheduled</v-card-title
                        >
                    </v-layout>
                    <v-layout justify-center>
                        <v-card-text class="body-1 text-center pt-1"
                            >Your payment schedule has been
                            scheduled</v-card-text
                        >
                    </v-layout>
                </div>
                <v-layout justify-center>
                    <v-card-actions class="pb-3">
                        <v-btn dark color="#4289b0" @click="goToDashboard"
                            >dashboard</v-btn
                        >
                    </v-card-actions>
                </v-layout>
            </v-card>
        </v-layout>
    </v-container>
</template>
<script>
import moment from "moment";
import { sum } from "../utils/helpers.js";
//import VueRecaptcha from "vue-recaptcha";

export default {
    // components: {
    //     VueRecaptcha,
    // },
    data() {
        return {
            status: "",
            payments: [],
            schedules: [],
            payment: {},
            processing: false,
            error: "",
            total: 0,
            recaptchaToken: "",
            recaptchaError: "",

            headers: [
                { text: "Date", value: "date", sortable: false, align: "left" },
                {
                    text: "Amount",
                    value: "amount",
                    sortable: false,
                    align: "right",
                },
            ],
            scheduleHeaders: [
                { text: "Date", value: "date", sortable: false, align: "left" },
                {
                    text: "Amount",
                    value: "amount",
                    sortable: false,
                    align: "right",
                },
            ],

            strings: {},
        };
    },
    computed: {},
    created() {
        this.payment = this.$store.state.app.payment;

        if (this.payment.paymentCategories) {
            this.payments = this.payment.paymentCategories.map((p) => ({
                date: "Today",
                amount: "$" + p.amount,
            }));
        }

        this.schedules = !this.payment.schedule
            ? null
            : this.payment.schedule.scheduledTransectionDetails.map((d) => ({
                  date: moment(d.scheduleDate).format("L"),
                  amount: "$" + d.amount,
              }));
    },
    methods: {
        formatDate(date) {
            return moment(date).format("L");
        },
        isMobile() {
            return window.innerWidth < 531;
        },
        getWidthStyle() {
            return {
                width: window.innerWidth < 500 ? "100%" : "70%",
            };
        },
        goBack() {
            if (this.$store.state.app.campaignName) {
                this.$router.push({
                    path: this.$store.state.app.campaignName,
                });

                return;
            }

            this.$router.push({
                name: "Dashboard",
            });
        },
        goToDashboard() {
            if (this.$store.state.app.campaignName) {
                this.$router.push({
                    path: this.$store.state.app.campaignName,
                });

                return;
            }

            this.$router.push({
                name: "Dashboard",
            });
        },
        onCaptchaVerified(recaptchaToken) {
            this.recaptchaToken = recaptchaToken;
        },
        getPrizeMessage() {
            const currentPayment = sum(
                this.payments.map((p) => {
                    const amount = p.amount.replace("$", "");

                    return +amount;
                })
            );
            const schedules = this.schedules
                ? sum(
                      this.schedules.map((p) => {
                          const amount = p.amount.replace("$", "");

                          return +amount;
                      })
                  )
                : 0;

            const transactions = this.$store.state.app.transactions
                ? sum(this.$store.state.app.transactions.map((t) => t.amount))
                : 0;

            const total = currentPayment + schedules + transactions;

            this.total = total;

            const message180 =
                "איר קענט אויפפיקן די הערליכע סידי און ביכל פון מסע הקודש ביים דינער אפיס אין";

            if (total >= 500) {
                return [
                    "איר גייט אי’’ה אריין אין די לייכטער גורל",
                    "איר גייט אי’’ה באקומען די ספר נפלא באילנא דחיי אויף די פאסט",
                    message180,
                ];
            } else if (total >= 360 && total < 500) {
                return [
                    "איר גייט אי’’ה באקומען די ספר נפלא באילנא דחיי אויף די פאסט",
                    message180,
                ];
            } else if (total >= 180 && total < 360) {
                return [message180];
            }

            return null;
        },
        async processPayment() {
            // if (!this.recaptchaToken) {
            //     this.recaptchaError = "Please confirm that you are not a robot";
            //     return;
            // }

            this.processing = true;

            try {
                const response = await this.$http.post(
                    "processPayment?",
                    Object.assign(this.payment, { token: this.recaptchaToken })
                );

                this.status = response.paymentLog
                    ? response.paymentLog.resultCode
                    : response.schedule
                    ? "A"
                    : "E";

                if (this.status == "A") {
                    this.$store.commit("app/setPayment", null);
                    this.$store.commit("app/setPaymentFields", null);
                }
            } catch (e) {
                if (e.message == "Recaptcha Failed") {
                    this.recaptchaError = "Captcha Failed! Please try again";
                    this.processing = false;

                    return;
                }

                this.processing = false;
                this.status = "E";
                this.error = e;

                return;
            }

            this.processing = false;
        },
    },
};
</script>
<style>
.full-width {
    width: 100% !important;
}
</style>
